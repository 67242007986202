<template>
  <v-container
    fluid
    id="booking-overview"
    ref="booking-overview"
    class="py-0"
  >
    <v-row
      id="booking-overview-title"
      v-if="showTitle"
    >
      <v-col
        cols="12"
        class="text-center text-h5 pb-0 font-weight-black"
      >
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              outlined
              flat
              style="border-color: #6B6B6B; border-width: 1px; border-style: solid; background-color: transparent;"
              v-on="on"
              v-bind="attrs"
            >
              <v-container
                fluid
                class="py-0"
              >
                <v-row
                  dense
                  align="center"
                >
                  <v-col cols="1" />
                  <v-col cols="10">
                    <span v-if="location.title">
                      {{ location.title }}
                    </span>
                    <span v-if="location.label">
                      {{ location.label }}
                    </span>
                    <span v-if="!locationPropHasValue">
                      {{ $t('noLocation') }}
                    </span>
                  </v-col>
                  <v-col cols="1">
                    <v-icon>
                      mdi-chevron-down
                    </v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </template>
          <v-list
            class="py-0"
            dense
          >
            <template v-for="locationItem in locations">
              <v-list-item
                :key="locationItem.uuid"
                @click="selectLocation(locationItem)"
              >
                <v-list-item-content>
                  <v-list-item-title v-if="locationItem.title">
                    {{ locationItem.title }}
                  </v-list-item-title>
                  <v-list-item-title v-else-if="locationItem.label">
                    {{ locationItem.label }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-else
                    class="font-italic"
                  >
                    {{ $t('noLocationName') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-date-picker
          no-title
          flat
          full-width
          class="bookings-date-picker"
          style="height: 350px; border-radius: 0; background-color: transparent;"
          v-model="selectedDate"
          color="primary"
          :events="calendarEvents"
          event-color="green lighten-1"
          @update:picker-date="monthChanged"
        />
      </v-col>
      <v-col
        cols="12"
        class="pt-0"
      >
        <div class="text-end">
          <v-icon
            color="green"
            x-small
            left
          >
            mdi-circle
          </v-icon>
          <span class="caption">
            {{ $t('dayHasBooking') }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <v-row id="booking-overview-kpi">
      <v-col cols="12">
        <v-container
          fluid
          class="py-0"
        >
          <v-row>
            <v-col
              key="bookings-count"
              :cols="kpiCols"
            >
              <v-card
                tile
                elevation="0"
                class="text-center transparent"
              >
                <div :style="`font-size: ${kpiFontSize};`">
                  {{ bookingsCount.toString().padStart(2, '0') }}
                </div>
                <div
                  class="text-lowercase text--secondary"
                  style="font-weight: 300;"
                >
                  {{ $t('bookingsLC') }}
                </div>
              </v-card>
            </v-col>
            <v-col
              key="booking-guests"
              :cols="kpiCols"
            >
              <v-card
                tile
                elevation="0"
                class="text-center transparent"
              >
                <div :style="`font-size: ${kpiFontSize};`">
                  {{ pax.toString().padStart(2, '0') }}
                </div>
                <div
                  class="text-lowercase text--secondary"
                  style="font-weight: 300;"
                >
                  {{ $t('guests') }}
                </div>
              </v-card>
            </v-col>
            <v-col
              key="checked-in"
              :cols="kpiCols"
            >
              <v-card
                tile
                elevation="0"
                class="text-center transparent"
              >
                <div :style="`font-size: ${kpiFontSize};`">
                  {{ paxRedeemed.toString().padStart(2, '0') }}
                </div>
                <div
                  class="text-lowercase text--secondary"
                  style="font-weight: 300;"
                >
                  {{ $t('checkedIn') }}
                </div>
              </v-card>
            </v-col>
            <!--template v-for="state in bookingStates">
              <v-col
                :key="`kpi-${state}`"
                :cols="kpiCols"
              >
                <v-card
                  tile
                  elevation="0"
                  class="text-center transparent"
                >
                  <div :style="`font-size: ${kpiFontSize};`">
                    {{ formattedBookingsInStateCount(state) }}
                  </div>
                  <div
                    class="text-lowercase text--secondary"
                    style="font-weight: 300;"
                  >
                    {{ state }}
                  </div>
                </v-card>
              </v-col>
            </template-->
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-divider />
    <v-row id="bookings-day-list">
      <v-col
        cols="12"
        v-if="isGettingBookings"
        class="text-center"
      >
        <v-progress-linear indeterminate />
      </v-col>
      <v-col
        cols="12"
        v-else
      >
        <v-container
          fluid
          class="pa-0"
        >
          <v-row v-if="!Array.isArray(bookedTimes) || bookedTimes.length < 1">
            <v-col
              cols="12"
              class="text-center text--secondary"
              style="font-weight: 300;"
            >
              {{ $t('noBookingsFoundForToday') }}
            </v-col>
          </v-row>
          <v-row v-else>
            <template v-for="(time, index) in bookedTimes">
              <v-col
                cols="12"
                :key="time.millis"
              >
                <v-container
                  fluid
                  class="pa-0"
                >
                  <v-row>
                    <v-col
                      cols="2"
                      class="pt-0 pb-0 pr-0"
                      style="font-weight: 300; font-size: 12px;"
                    >
                      {{ time.text }}
                    </v-col>
                    <v-divider vertical />
                    <v-col class="pt-0 pb-0">
                      <div
                        class="pb-1"
                        style="font-weight: 300; font-size: 12px;"
                      >
                        {{ totalPaxInTimeSlot(time.millis) }} {{ $t('paxTotal') }}
                      </div>
                      <template v-for="booking in bookingsInTimeSlot(time.millis)">
                        <v-card
                          flat
                          class="transparent"
                          :key="booking.uuid"
                          @click="selectBooking(booking)"
                        >
                          <v-container
                            class="py-0 pl-1"
                            :key="booking.uuid"
                          >
                            <v-row dense>
                              <v-col
                                cols="2"
                                class="pa-0"
                              >
                                <v-menu>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-row
                                      align="center"
                                      justify="center"
                                    >
                                      <v-icon
                                        :color="bookingColor(booking)"
                                        x-small
                                        left
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-circle
                                      </v-icon>
                                      <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-chevron-double-left
                                      </v-icon>
                                    </v-row>
                                  </template>
                                  <v-list
                                    dense
                                    class="py-0"
                                    v-if="booking && booking.status !== '0'"
                                  >
                                    <v-list-item
                                      class="black white--text"
                                      @click="processBookingRedemption(booking)"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 16px;">
                                          <span>
                                            {{ checkInText(booking) }}
                                          </span>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <template v-for="state in bookingStates">
                                      <v-list-item
                                        v-if="state.value !== 'canceled' && state.value !== 'cancelled'"
                                        :key="state.value"
                                        @click="updateBookingState(booking, state)"
                                      >
                                        <v-list-item-avatar
                                          :color="state.color"
                                          size="8"
                                        />
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            {{ state.text }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                    <v-list-item
                                      class="red white--text"
                                      @click="showConfirm(booking)"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 16px;">
                                          {{ $t('cancelBooking') }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-col>
                              <v-col style="font-size: 12px;">
                                <div>
                                  {{ bookingCustomer(booking) }} ({{ booking.quantity }} {{ $t('PAX') }})
                                  <v-icon
                                    v-if="booking.externalNote && !booking.internalNote"
                                    x-small
                                  >
                                    mdi-comment-text-outline
                                  </v-icon>
                                  <v-icon
                                    v-if="booking.internalNote && !booking.externalNote"
                                    x-small
                                  >
                                    mdi-comment-outline
                                  </v-icon>
                                  <v-icon
                                    v-if="booking.externalNote && booking.internalNote"
                                    x-small
                                  >
                                    mdi-comment-text-multiple-outline
                                  </v-icon>
                                </div>
                                <div
                                  class="caption"
                                  v-if="Array.isArray(booking.locationVariations) && booking.locationVariations.length > 0"
                                >
                                  {{ booking.locationVariations.map(v => v.label).join(', ') }}
                                </div>
                                <div v-else>
                                  {{ (booking && booking.item) ? booking.item.title : '' }}
                                </div>
                              </v-col>
                            </v-row>
                            <v-row
                              v-if="booking && booking.externalNote"
                              dense
                              align="center"
                            >
                              <v-col
                                cols="1"
                                class="pa-0"
                              >
                                <v-icon x-small>
                                  mdi-text
                                </v-icon>
                              </v-col>
                              <v-col
                                style="font-size: 12px; font-weight: 700; font-style: italic;"
                              >
                                {{ booking.externalNote }}
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-divider
                :key="index"
                inset
                v-if="bookedTimes.length - 1 > index"
              />
            </template>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-divider />
    <v-row
      id="booking-overview-state-sorted-bookings"
      v-if="false"
    >
      <v-col cols="12">
        <v-expansion-panels
          tile
          flat
          multiple
          class="transparent"
          dense
        >
          <template v-for="state in bookingStates">
            <v-expansion-panel
              dense
              class="transparent"
              :key="`bookings-${state}`"
            >
              <v-expansion-panel-header
                class="text-uppercase"
                style="font-weight: 500; font-size: 18px;"
              >
                {{ `${state} (${ bookingsInState(state).length })` }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="bookingsInState(state).length === 0">
                  {{ $t('noReservationsAreCurrentlyInThisState') }}
                </div>
                <div v-else>
                  {{ bookingsInState(state) }}
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-dialog
      v-if="showConfirmCancel"
      :value="showConfirmCancel"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('confirmCancelBookingText') }}
        </v-card-title>
        <v-card-text>
          {{ $t('confirmCancelBookingInfo', { customer: (selectedBooking && selectedBooking.customer && selectedBooking.customer.address && selectedBooking.customer.address.name_line) ? selectedBooking.customer.address.name_line : '', date: $moment(selectedBooking.startsAtTime * 1000).format('DD/MM/YYYY HH:mm') }) }}
        </v-card-text>
        <v-card-text v-if="cancelError">
          <v-alert type="error">
            {{ cancelError }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            :disabled="processingBooking"
            @click="cancelShowConfirm"
          >
            {{ $t('close') }}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            :loading="processingBooking"
            :disabled="processingBooking"
            @click="cancelBooking(selectedBooking)"
          >
            {{ $t('confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'

const TIME_IN_MILLIS_LENGTH = 13

export default {
  name: "SimpleBookingOverview",
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    location: {
      type: Object,
      default: () => ({})
    },
    calendarEvents: {
      type: Array,
      default: () => []
    },
    locations: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      kpiCols: '4',
      kpiFontSize: 16,
      calendarEvents2: [],
      updatingBookingState: false,
      showConfirmCancel: false,
      selectedBooking: null,
      processingBooking: false,
      cancelError: null
    }
  },
  computed: {
		salesCloudColors() {
			return this.$store.state.salesCloudColors
		},
    availableBookingStates() {
      return this.$store.state.availableBookingStates
    },
    locationPropHasValue() {
      return typeof this.location !== 'undefined' && this.location !== null && Object.keys(this.location).length > 0
    },
    isGettingBookings() {
      return this.$store.state.isUpdatingBookings
    },
    bookingStates() {
      return this.$store.state.availableBookingStates
    },
    bookings() {
      const bookings = this.$store.state.bookings

      if(!Array.isArray(bookings)) {
        return []
      }
      const filteredBookings = bookings.filter(booking => booking && (booking.status === '1' || booking.status === true))

      if(typeof this.location !== 'undefined' && this.location !== null && Object.keys(this.location).length > 0) {
        return filteredBookings.filter(booking => booking && booking.location && booking.location.uuid === this.location.uuid)
      }
      return filteredBookings
    },
    selectedDate: {
      set(value) {
        this.$store.commit('updateSelectedDate', value)
      },
      get() {
        return this.$store.state.selectedDate
      }
    },
    bookingsCount() {
      if(Array.isArray(this.bookings)) {
        return this.bookings.length
      }
      return 0
    },
    pax() {
      let pax = 0

      if(!Array.isArray(this.bookings)) {
        return pax
      }

      for(let i in this.bookings) {
        const booking = this.bookings[i]
        pax = pax + booking.quantity
      }

      return pax
    },
    paxRedeemed() {
      let pax = 0

      if(!Array.isArray(this.bookings)) {
        return pax
      }

      for(let i in this.bookings) {
        const booking = this.bookings[i]

        if(booking.redeemedAt === 0 || booking.redeemedAt === null || booking.redeemedAt === undefined) {
          continue
        }

        pax = pax + booking.quantity
      }

      return pax
    },
    bookedTimes() {
      const bookingTimes = []
      if(Array.isArray(this.bookings) && this.bookings.length > 0) {
        for(let bookingIndex = 0; bookingIndex < this.bookings.length; bookingIndex++) {
          const booking = this.bookings[bookingIndex]
          const startsAtTimeInMillis = booking.startsAtTime * 1000
          if(!bookingTimes.some(t => t && t.millis === startsAtTimeInMillis)) {
            const displayText = moment(startsAtTimeInMillis).format('HH:mm')
            bookingTimes.push({
              millis: startsAtTimeInMillis,
              text: displayText
            })
          }
        }
      }
      return bookingTimes
    }
  },
  methods: {
	  convertTimeToMillis(time) {
		  if(typeof time === 'undefined' || time === null) {
			  return 0
		  }
		  // Find the difference in lengths and make sure to remove possible dots (aka seconds shown as 1234567890.123
		  const difference = TIME_IN_MILLIS_LENGTH - time.toString().replace(/\./g, '').length
		  return time * Number('1e' + difference)
	  },
		monthChanged(newMonth) {
			this.$emit('monthChanged', newMonth)
		},
		checkInText(booking) {
			if (booking && booking.redeemedAt) {
				return this.$t('undoCheckIn')
			}
			return this.$t('checkIn')
		},
		processBookingRedemption(booking) {
      if(booking) {
        if(!booking.redeemedAt || booking.redeemedAt <= 0) {
          this.redeemBooking(booking)
        }
				else {
					this.unRedeemBooking(booking)
				}
      }
    },
		redeemBooking(booking) {
			this.processingBooking = true
			this.$store.dispatch('redeemBooking', booking)
				.then(result => {
					if (!result) {
						this.$store.commit('updateActionError', {
							message: this.$t('errorOccurred'),
							subMessage: this.$t('unableToCheckinBookingPleaseTryAgain')
						})
						this.processingBooking = false
						return
					}
					this.$emit('close')
				})
				.catch(() => {
					this.$store.commit('updateActionError', {
						message: this.$t('errorOccurred'),
						subMessage: this.$t('unableToCheckinBookingPleaseTryAgain')
					})
				})
				.finally(() => {
					this.refreshBookings()
					this.processingBooking = false
				})
		},
		unRedeemBooking(booking) {
			this.processingBooking = true
			this.$store.dispatch('unRedeemBooking', booking)
				.then(result => {
					if (!result) {
						this.$store.commit('updateActionError', {
							message: this.$t('errorOccurred'),
							subMessage: this.$t('unableToCheckinBookingPleaseTryAgain')
						})
						this.processingBooking = false
						return
					}
					this.$emit('close')
				})
				.catch(() => {
					this.$store.commit('updateActionError', {
						message: this.$t('errorOccurred'),
						subMessage: this.$t('unableToCheckinBookingPleaseTryAgain')
					})
				})
				.finally(() => {
					this.refreshBookings()
					this.processingBooking = false
				})
		},
		refreshBookings() {
			if (this.selectedDate) {
				const dateMoment = moment(this.selectedDate)

				const payload = {
					startTimeInMillis: dateMoment.startOf('day').valueOf(),
					endTimeInMillis: dateMoment.endOf('day').valueOf()
				}
				this.$store.dispatch('getBookingsByDate', payload)
			}
			else {
				this.$store.dispatch('getBookingsByDate', {
					startTimeInMillis: this.$store.state.startTimeInMillis,
					endTimeInMillis: this.$store.state.endTimeInMillis
				})
			}
		},
    selectLocation(location) {
      this.$store.commit('updateSelectedAppBarTab', location.uuid)
    },
    cancelShowConfirm() {
      this.showConfirmCancel = false
      this.selectedBooking = null
    },
    showConfirm(booking) {
      this.selectedBooking = booking
      this.showConfirmCancel = true
    },
    cancelBooking(booking) {
      this.processingBooking = true

      this.cancelError = null

      if (!booking.item || !booking.item.uuid || !booking.customer || !booking.customer.uuid) {
        this.cancelError = this.$t('unableToCancelBookingDueToMissingIdentifiers')
        this.processingBooking = false
        return
      }

      const b = {
        uuid: booking.uuid,
        item: booking.item.uuid,
        customer: booking.customer.uuid,
        namespace: booking.namespace,
        status: false
      }

      this.$store.dispatch('updateBooking', b)
        .then(result => {
          if (result) {
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'),
              subMessage: this.$t('youHaveSuccessfullyCancelledTheBooking')
            })
            this.$emit('closed')
          } else {
            this.cancelError = this.$t('couldNotCancelBooking')
          }
        })
        .finally(() => {
          this.selectedBooking = null
          this.showConfirmCancel = false
          this.processingBooking = false
        })
    },
    updateBookingState(booking, state) {
      if(typeof booking !== 'undefined' && booking !== null) {
        this.updatingBookingState = true

        if(typeof booking.uuid !== 'undefined' && booking.uuid !== null) {
          this.$store.dispatch('updateBookingState', { bookingUUID: booking.uuid, newBookingState: state.value }).then(result => {
            // Return result should be a boolean so if it is false it had an error of some kind
            if(result) {
              // updated
            }
          }).catch(() => {
            // Handling error
          }).finally(() => {
            this.updatingBookingState = false
          })
        } else {
          this.updatingBookingState = false
        }
      }
    },
    getBookingsPerDay(date) {
      this.$store.dispatch('getBookingsPerDayForSelectedMonth', { selectedDate: date, locationUUID: this.location.uuid }).then(results => {
        if(Array.isArray(results) && results.length > 0) {
          this.calendarEvents2 = results.map(a => a.date)
        } else {
          this.calendarEvents2 = []
        }
      })
    },
    selectBooking(booking) {
      this.$emit('selectBooking', booking)
    },
    bookingsInState(bookingState) {
      if(typeof bookingState !== 'undefined' && bookingState !== null && Array.isArray(this.bookings) && this.bookings.length > 0) {
        return this.bookings.filter(booking => booking && booking.state && booking.state.toLowerCase() === bookingState.toLowerCase())
      }
      return []
    },
    formattedBookingsInStateCount(bookingState) {
      let count = 0
      count = this.bookingsInState(bookingState).length
      return count.toString().padStart(2, '0')
    },
    bookingsInTimeSlot(timeInMillis) {
      if(Array.isArray(this.bookings) && this.bookings.length > 0) {
        if(typeof timeInMillis !== 'undefined') {
          return this.bookings.filter(booking => this.convertTimeToMillis(booking.startsAtTime) === timeInMillis)
        }
      }
      return []
    },
		totalPaxInTimeSlot(timeInMillis) {
			if(Array.isArray(this.bookings) && this.bookings.length > 0) {
				if(typeof timeInMillis !== 'undefined') {
					const bookings = this.bookings.filter(booking => this.convertTimeToMillis(booking.startsAtTime) === timeInMillis)
					return bookings.reduce((acc, currentBooking) => acc + currentBooking.quantity, 0)
				}
			}
			return 0
		},
    bookingCustomer(booking) {
      if(typeof booking !== 'undefined' && booking !== null) {
        if(typeof booking.customer !== 'undefined' && booking.customer !== null) {
          if(typeof booking.customer.address !== 'undefined' && booking.customer.address !== null) {
            if(typeof booking.customer.address.first_name !== 'undefined' && booking.customer.address.first_name !== null) {
              return booking.customer.address.first_name
            }
          }
        }
      }
      return 'No name'
    },
    bookingColor(booking) {
      if(typeof booking !== 'undefined' && booking !== null) {
        const bookingState = Array.isArray(this.availableBookingStates) ? this.availableBookingStates.find(state => state && state.value === booking.state) : null

        if(bookingState) {
          const isRedeemed = booking.redeemedAt ? booking.redeemedAt > 0 : false
          if(isRedeemed) {
            return this.salesCloudColors.softBlack
          }
          return bookingState.color
        }

        if(booking.status === '1') {
          const isRedeemed = booking.redeemedAt ? booking.redeemedAt > 0 : false
          if(isRedeemed) {
            return this.salesCloudColors.softBlack
          }
          return this.salesCloudColors.blue
        }

        if(booking.status === '0') {
          return this.salesCloudColors.brightRed
        }
      }
      return this.salesCloudColors.blue
    }
  },
  mounted() {
    const bookingOverviewRef = this.$refs["booking-overview"]
    if(bookingOverviewRef) {
      const width = bookingOverviewRef.offsetWidth
      if(width > 1280) {
        this.kpiCols = '2'
      }
      if(width <= 1280 && width > 640) {
        this.kpiCols = '3'
      }
      if(width <= 640) {
        this.kpiCols = '4'
      }
    }
  },
	watch: {
    //
	}
}
</script>

<style scoped>
.bookings-date-picker >>> .v-picker__body {
  background-color: #eeeeee;
}
</style>
