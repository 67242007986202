<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="isGettingBookings"
        outlined
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('sendEmail') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('sendEmailToCustomersWithReservedBookings') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <h4 class="pb-2">
            {{ validEmails.length }}{{ $t('customersHaveValidEmails') }}
          </h4>
          <h4 class="pb-2">
            {{ invalidEmailsLength }} {{ $t('customersHaveInvalidEmails') }}
          </h4>
          <v-row>
            <v-col
              class="mt-4 mb-2"
              cols="6"
            >
              <TimeSelectorDialog
                :selected-time="timeFrom"
                @time="setTimeFrom"
                :label="$t('timeFrom')"
              />
            </v-col>
            <v-spacer />
            <v-col
              class="mt-4 mb-2"
              cols="6"
            >
              <TimeSelectorDialog
                :selected-time="timeTo"
                @time="setTimeTo"
                :label="$t('timeTo')"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                dense
                hide-details
                :label="$t('items')"
                outlined
                v-model="selectedItems"
                :items="eventItems"
                item-text="title"
                item-value="uuid"
                multiple
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                hide-details
                v-model="subject"
                :label="$t('subject')"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                hide-details
                v-model="message"
                :label="$t('enterMessage')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="onCancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="sendMessages"
          :disabled="isButtonDisabled"
        >
          {{ $t('send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TimeSelectorDialog from "../common/TimeSelectorDialog"

export default {
  name: 'SendMessageToAll',
  props: {
    bookings:{
      type: Array, 
      default: () => []
    },
    isGettingBookings: {
      type: Boolean 
    }
  },
  components: {
    TimeSelectorDialog
  },
  data() {
    return {
      dialog: false,
      subject: '',
      message: '',
      timeFrom: null,
      timeTo: null,
      modalFrom: false,
      modalTo: false,
      selectedItems: [],
    }
  },
  computed: {
    validEmails: function() {
      let validEmails = []

      if(!Array.isArray(this.bookings)) {
        return validEmails
      }

      let bookings = this.itemsFilter()

      for(let i = 0; i < bookings.length; i++) {

        const booking = bookings[i]

        if(!booking.customer || booking.status === '0') {
          continue
        }

        const customerEmail = booking.customer.email

        if(!customerEmail || customerEmail.length <= 0 || !customerEmail.includes('@')) {
          continue
        }

        if(!validEmails.includes(customerEmail) && this.pushEmail(booking)) {
          validEmails.push(customerEmail)
        }

      }

      return validEmails
    },
    invalidEmailsLength: function() {

      let invalidEmails = []

      if(!Array.isArray(this.bookings)) {
        return invalidEmails
      }

      let bookings = this.itemsFilter()

      for(let i = 0; i < bookings.length; i++) {

        const booking = bookings[i]

        if(!booking.customer || booking.status === '0') {
          continue
        }

        const customerEmail = booking.customer.email

        if(customerEmail && customerEmail.length > 0 && customerEmail.includes('@')) {
          continue
        }

        if(!invalidEmails.includes(customerEmail) && this.pushEmail(booking)) {
          invalidEmails.push(customerEmail)
        }

      }

      return invalidEmails.length
    },
    eventItems() {

      if(this.$store.state.items === null || this.$store.state.items === undefined) {
        return []
      }

      return this.$store.state.items.filter(item => item.type === 'event' && item.status === true).sort((i1, i2) => i1.title.localeCompare(i2.title))
    },
    isButtonDisabled() {
      if(this.timeFrom !== null && this.timeFrom !== undefined && this.timeTo !== null && this.timeTo !== undefined) {
        if(this.subject.length > 0 && this.message.length > 0 && this.timeFrom < this.timeTo) {
          return false
        }
      }
      if(this.timeFrom === null && this.timeTo === null) {
        if(this.subject.length > 0 && this.message.length > 0) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    sendMessages() {

      if(!Array.isArray(this.bookings)) {
        return
      }

      const bookingsPayload = {}
      let validBookingUUIDS = []

      bookingsPayload.subject = this.subject
      bookingsPayload.message = this.message

      let bookings = this.itemsFilter()

      for(let i = 0; i < bookings.length; i++) {

        const booking = bookings[i]

        if(!booking.customer || booking.status === '0') {
          continue
        }

        const customerEmail = booking.customer.email

        if(!this.validEmails.includes(customerEmail)) {
          continue
        }

        validBookingUUIDS.push(booking.uuid)

      }

      bookingsPayload.uuids = validBookingUUIDS

      this.$store.dispatch('sendMessageToBookingOwners', bookingsPayload)

      this.subject = ''
      this.message = ''
      this.timeFrom = null
      this.timeTo = null
      this.dialog = false
      this.selectedItems = []

    },
    onCancel() {
      this.subject = ''
      this.message = ''
      this.timeFrom = null
      this.timeTo = null
      this.dialog = false
      this.selectedItems = []
    },
    getBookingStartAndEndTime(booking) {
      var startDate = new Date(booking.startsAtTime * 1000)
      var startHour = startDate.getHours()
      var startMinutes = startDate.getMinutes()

      var endDate = new Date(booking.endsAtTime * 1000)
      var endHour = endDate.getHours()
      var endMinutes = endDate.getMinutes()

      var bookingStartTime = startHour + ":" + startMinutes
      var bookingEndTime = endHour + ":" + endMinutes
      return [bookingStartTime, bookingEndTime]
    },
    itemsFilter() {
      let bookings = []

      if(this.selectedItems.length > 0) {

        for(let i in this.bookings) {

          const booking = this.bookings[i]

          if(!booking.item) {
            continue
          }

          if(!booking.item.uuid) {
            continue
          }

          if(this.selectedItems.includes(booking.item.uuid)) {
            bookings.push(booking)
          }

        }

      }
      else {
        bookings = this.bookings
      }

      return bookings
    },
    pushEmail(booking) {
      if(this.timeFrom !== null && this.timeFrom !== undefined && this.timeTo !== null && this.timeTo !== undefined) {

        const [bookingStartTime, bookingEndTime] = this.getBookingStartAndEndTime(booking)

        return bookingStartTime < this.timeTo && bookingEndTime > this.timeFrom

      }
      return true
    },
    setTimeFrom(time) {
      this.timeFrom = time
    },
    setTimeTo(time) {
      this.timeTo = time
    },
  },
}
</script>

<style scoped>
.headline {
  font-size: 1.2rem !important
}
</style>
