<template>
  <v-container
    fluid
    class="py-0 px-0"
    :class="isOnMobile ? 'px-0' : ''"
  >
    <v-row
      no-gutters
      align="center"
      justify="center"
      class="mx-0"
      v-if="!showCustomDateRange"
      style="margin-top: 0;"
    >
      <v-col class="overflow-x-auto">
        <v-row class="flex-nowrap">
          <template v-for="(date, index) in dates">
            <v-col
              cols="auto"
              :key="'year-'+index"
              v-if="date.getMonth() === 0 && date.getDate() === 1"
              style="border-width: 1px; border-color: black dimgrey black black; background-color: black; color: white; width: 35px;"
              :style="(index === 0 ? 'border-style: solid;' : 'border-style: solid solid solid none;')"
              class="text-center pa-0"
            >
              <div class="month-sideways mt-5 font-weight-bold">
                {{ date.getFullYear() }}
              </div>
            </v-col>
            <v-col
              cols="auto"
              :key="'month-'+index"
              v-if="date.getDate() === 1"
              style="border-width: 1px; border-color: black; background-color: black; color: white;"
              :style="(index === 0 ? 'border-style: solid;' : 'border-style: solid solid solid none;')"
              class="text-center pa-0"
            >
              <div class="month-sideways mt-5 font-weight-bold">
                {{ getShortMonthNames(date.getMonth()) }}
              </div>
            </v-col>
            <v-col
              :key="index"
              :id="(date === today ? 'today' : '') + ((date !== today && date === selectedDate) ? 'selected-date' : '')"
              cols="auto"
              class="text-center pa-0"
              @click="selectedDate = date"
              style="cursor: pointer; border-width: 1px; border-color: black; width: 65px;"
              :style="(today === date ? 'background: lightblue;' : '') + (index === 0 ? 'border-style: solid;' : 'border-style: solid solid solid none;') + isSunday(date)"
            >
              <div>{{ getShortWeekdayNames(date.getDay()) }}</div>
              <div class="pb-1">
                <v-avatar
                  size="36"
                  :color="date === selectedDate ? 'blue' : ''"
                  :class="date === selectedDate ? 'white--text' : ''"
                >
                  {{ date.getDate() }}
                </v-avatar>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="pb-0 pt-0"
      cols="5"
      justify="space-around"
      align="center"
      v-if="!show"
    >
      <v-col
        cols="1"
        v-if="!show"
      >
        <v-btn
          class="ml-3"
          dark
          :color="primaryColor"
          @click="previousDay"
          :disabled="isGettingBookings"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col
        align="center"
        cols="2"
      >
        <v-btn
          v-if="showAdd === undefined || showAdd === true"
          color="green"
          class="mr-5 flex white--text"
          @click="$emit('addBooking')"
        >
          {{ $t('addBooking') }}
        </v-btn>
      </v-col>
      <v-col
        align="center"
        cols="3"
      >
        <DateSelectorButton
          :date="selectedDate"
          @newDate="getBookingsByDate"
          :disabled="isGettingBookings"
        />
        <v-btn
          class="flex ml-3"
          outlined
          :color="primaryColor"
          :dark="!isGettingBookings"
          @click="setToday"
          :disabled="isGettingBookings"
          :loading="isGettingBookings"
        >
          {{ $t('today') }}
        </v-btn>
        <v-btn
          @click="refresh"
          class="ml-3"
          :loading="isGettingBookings"
          icon
          small
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
      <v-col
        align="center"
        cols="2"
      >
        <sendMessageToAll
          v-if="showSendEmail === undefined || showSendEmail === true"
          :is-getting-bookings="isGettingBookings"
          :bookings="bookings"
        />
      </v-col>
      <v-col
        align="right"
        cols="1"
        v-if="!show"
      >
        <v-btn
          class="mr-3"
          dark
          :color="primaryColor"
          @click="nextDay"
          :disabled="isGettingBookings"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateSelectorButton from "@/components/common/DateSelectorButton"
import sendMessageToAll from '@/components/booking/sendMessageToAll'

export default {
	name: "HorizontalCalendarPicker",
	components: {
		DateSelectorButton,
		sendMessageToAll
	},
	props: {
		disabled: {
			type: Boolean
		}, 
		gettingCustomRange: {
			type: Boolean 
		}, 
		noCustomRange: {
			type: Boolean 
		}, 
		bookings: {
			type: Array,
			default: () => []
		},
		showAdd: {
			type: Boolean
		},
		showSendEmail:{
			type: Boolean 
		}
	},
	data() {
		return {
			selectedDate: new Date(new Date().setHours(0,0,0,0)),
			previousLength: 365,
			nextLength: 365,
			showCustomDateRange: false
		}
	},
	computed: {
		startTimeInMillis: {
			set(value) {
				this.$store.commit('updateStartTimeInMillis', value)
			},
			get() {
				return this.$store.state.startTimeInMillis
			}
		},
		endTimeInMillis: {
			set(value) {
				this.$store.commit('updateEndTimeInMillis', value)
			},
			get() {
				return this.$store.state.endTimeInMillis
			}
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		isOnMobile() {
			return this.$vuetify.breakpoint.width < 800
		},
		showExtras() {
			return true
		},
		show() {
			return !!(this.isOnMobile && this.showExtras) || this.showCustomDateRange
		},
		startOfDay() {
			return new Date(this.selectedDate).setHours(0,0,0,0)
		},
		endOfDay() {
			return new Date(this.selectedDate).setHours(23,59,59,999)
		},
		today() {
			return new Date(new Date().setHours(0,0,0,0))
		},
		dates() {
			return [...this.previousDates, this.today, ...this.nextDates]
		},
		currentDateIndex() {
			return this.dates.indexOf(this.selectedDate)
		},
		previousDates() {
			return Array.from(new Array(this.previousLength), (x, index) => {
				let d = new Date(this.today)
				d = new Date(d.setDate(d.getDate() - (index+1)))
				return d
			}).reverse()
		},
		nextDates() {
			return Array.from(Array(this.nextLength), (x, index) => {
				let d = new Date(this.today)
				return new Date(d.setDate(d.getDate() + (index+1)))
			})
		},
		isGettingBookings() {
			return this.$store.state.isUpdatingBookings
		}
	},
	methods: {
		refresh() {
			this.$emit('selectedDate', this.selectedDate)
		},
		updateStartTime(value) {
			this.startTimeInMillis = value
		},
		updateEndTime(value) {
			this.endTimeInMillis = value
		},
		setShowCustomRange(booleanValue){
			this.showCustomDateRange = booleanValue
			this.$emit('showCustomRange', booleanValue)
			if(booleanValue === false) {
				this.setToday()
			}
		},
		isSunday(date) {
			const d = new Date(date).getDay()
			if(d === 0) {
				return ' background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,226,226,1) 100%);'
			} else {
				return ''
			}
		},
		setToday() {
			this.selectedDate = this.today

			this.$nextTick(() => {
				let today = document.getElementById('today')
				if(today) {
					today.scrollIntoView({block: "center", inline: "center"})
				}
			})
		},
		nextDay() {
			let currentDatesIndex = this.dates.indexOf(this.selectedDate)
			this.selectedDate = this.dates[currentDatesIndex + 1]
			let selected = document.getElementById('selected-date')
			if(selected) {
				selected.scrollIntoView({block: "center", inline: "center"})
			}
			// let date = new Date(this.selectedDate)
			// date.setDate(date.getDate() + 1)
			// this.selectedDate = date
		},
		previousDay() {
			let currentDatesIndex = this.dates.indexOf(this.selectedDate)
			this.selectedDate = this.dates[currentDatesIndex - 1]
			let selected = document.getElementById('selected-date')
			if(selected) {
				selected.scrollIntoView({block: "center", inline: "center"})
			}
		},
		getBookingsByDate: function (date) {
			this.selectedDate = date
			this.dates.forEach((d, i) => {
				if (d.getTime() === date.getTime()) {
					this.selectedDate = this.dates[i]
				}
			})

			this.$nextTick(() => {
				let selected = document.getElementById('selected-date')
				if (selected) {
					selected.scrollIntoView({block: "center", inline: "center"})
				}
			})
		},
		getFullMonthNames(monthNumber) {
			switch(monthNumber) {
				case 0:
					return this.$t('january')
				case 1:
					return this.$t('february')
				case 2:
					return this.$t('march')
				case 3:
					return this.$t('april')
				case 4:
					return this.$t('may')
				case 5:
					return this.$t('june')
				case 6:
					return this.$t('july')
				case 7:
					return this.$t('august')
				case 8:
					return this.$t('september')
				case 9:
					return this.$t('october')
				case 10:
					return this.$t('november')
				case 11:
					return this.$t('december')
				default:
					return ''
			}
		},
		getShortMonthNames(monthNumber) {
			switch(monthNumber) {
				case 0:
					return this.$t('januaryShort')
				case 1:
					return this.$t('februaryShort')
				case 2:
					return this.$t('marchShort')
				case 3:
					return this.$t('aprilShort')
				case 4:
					return this.$t('mayShort')
				case 5:
					return this.$t('juneShort')
				case 6:
					return this.$t('julyShort')
				case 7:
					return this.$t('augustShort')
				case 8:
					return this.$t('septemberShort')
				case 9:
					return this.$t('octoberShort')
				case 10:
					return this.$t('novemberShort')
				case 11:
					return this.$t('decemberShort')
				default:
					return ''
			}
		},
		getFullWeekdayNames(weekdayNumber) {
			switch(weekdayNumber) {
				case 0:
					return this.$t('sunday')
				case 1:
					return this.$t('monday')
				case 2:
					return this.$t('tuesday')
				case 3:
					return this.$t('wednesday')
				case 4:
					return this.$t('thursday')
				case 5:
					return this.$t('friday')
				case 6:
					return this.$t('saturday')
				default:
					return ''
			}
		},
		getShortWeekdayNames(weekdayNumber) {
			switch(weekdayNumber) {
				case 0:
					return this.$t('sundayShort')
				case 1:
					return this.$t('mondayShort')
				case 2:
					return this.$t('tuesdayShort')
				case 3:
					return this.$t('wednesdayShort')
				case 4:
					return this.$t('thursdayShort')
				case 5:
					return this.$t('fridayShort')
				case 6:
					return this.$t('saturdayShort')
				default:
					return ''
			}
		}
	},
	created() {
		this.startTimeInMillis = this.$moment(this.selectedDate.getTime()).startOf('day').valueOf()
		this.endTimeInMillis = this.$moment(this.selectedDate.getTime()).endOf('day').valueOf() + 1
	},
	mounted() {
		let today = document.getElementById('today')
		if(today) {
			today.scrollIntoView({block: "center", inline: "center"})
		}
	},
	watch: {
		// currentDateIndex(value) {
		// 	console.log(value)
		// },
		selectedDate(value) {
			this.$emit('selectedDate', value)
      this.$store.commit('updateSelectedDate', value)
			this.startTimeInMillis = this.$moment(value.getTime()).startOf('day').valueOf()
			this.endTimeInMillis = this.$moment(value.getTime()).endOf('day').valueOf() + 1
		}
	}
}
</script>

<style scoped>
.month-sideways {
	transform: rotate(-90deg) translateX(-200px);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
	text-transform: uppercase;
}

.description {
	position: -webkit-sticky;
	position: sticky;
	left: 0; /* <-- become sticky once touching left edge */
}
</style>