import { render, staticRenderFns } from "./ExpandableWidthDrawer.vue?vue&type=template&id=ba79aa34&scoped=true"
import script from "./ExpandableWidthDrawer.vue?vue&type=script&lang=js"
export * from "./ExpandableWidthDrawer.vue?vue&type=script&lang=js"
import style0 from "./ExpandableWidthDrawer.vue?vue&type=style&index=0&id=ba79aa34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba79aa34",
  null
  
)

export default component.exports