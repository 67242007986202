<template>
  <v-card
    elevation="2"
    :disabled="isDisabled"
  >
    <v-card-title>{{ $t('bookingDateAndTime') }}</v-card-title>
    <v-card-subtitle class="error--text">
      {{ $t('workInProgressSelectionBelowWillHaveNoEffectOnBooking') }}
    </v-card-subtitle>
    <v-card-text ref="dateTimeSelection">
      <v-menu
        ref="fromDate"
        :return-value.sync="fromDate"
        v-model="fromDateMenu"
        :close-on-content-click="false"
        offset-y
        transition="scale-transition"
        :max-width="datePickerWidth"
      >
        <template v-slot:activator="{on, attr}">
          <v-text-field
            readonly
            :label="$t('fromDate')"
            v-model="fromDate"
            prepend-icon="mdi-calendar"
            v-bind="attr"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="fromDate"
          v-if="fromDateMenu"
          no-title
          full-width
          @click:date="$refs.fromDate.save(fromDate)"
        />
      </v-menu>
      <v-menu
        ref="fromTime"
        :return-value.sync="fromTime"
        v-model="fromTimeMenu"
        :close-on-content-click="false"
        offset-y
        transition="scale-transition"
      >
        <template v-slot:activator="{on, attr}">
          <v-text-field
            readonly
            :label="$t('fromTime')"
            v-model="fromTime"
            prepend-icon="mdi-clock-outline"
            v-bind="attr"
            v-on="on"
          />
        </template>
        <v-time-picker
          v-model="fromTime"
          v-if="fromTimeMenu"
          full-width
          format="24hr"
          @click:minute="$refs.fromTime.save(fromTime)"
        />
      </v-menu>
      <v-menu
        ref="toDate"
        :return-value.sync="toDate"
        v-model="toDateMenu"
        :close-on-content-click="false"
        offset-y
        transition="scale-transition"
        :max-width="datePickerWidth"
      >
        <template v-slot:activator="{on, attr}">
          <v-text-field
            readonly
            :label="$t('toDate')"
            v-model="toDate"
            prepend-icon="mdi-calendar"
            v-bind="attr"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="toDate"
          v-if="toDateMenu"
          no-title
          full-width
          @click:date="$refs.toDate.save(toDate)"
        />
      </v-menu>
      <v-menu
        ref="toTime"
        :return-value.sync="toTime"
        v-model="toTimeMenu"
        :close-on-content-click="false"
        offset-y
        transition="scale-transition"
      >
        <template v-slot:activator="{on, attr}">
          <v-text-field
            readonly
            :label="$t('toTime')"
            v-model="toTime"
            prepend-icon="mdi-clock-outline"
            v-bind="attr"
            v-on="on"
          />
        </template>
        <v-time-picker
          v-model="toTime"
          v-if="toTimeMenu"
          full-width
          format="24hr"
          @click:minute="$refs.toTime.save(toTime)"
        />
      </v-menu>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
	name: "DateTimeSelection",
	props: {
		isDisabled: {
      type: Boolean
    },
		selectedTimeSlot: {
      type: Object,
      default: () => {}
    }
	},
	data() {
		return {
			fromDate: null,
			fromDateMenu: false,
			fromTime: null,
			fromTimeMenu: false,
			toDate: null,
			toDateMenu: false,
			toTime: null,
			toTimeMenu: false,
			datePickerWidth: 500
		}
	},
	mounted() {
		if(this.selectedTimeSlot) {
			let startTime = new Date(this.selectedTimeSlot.startTimeInMillis).toISOString()
			let endTime = new Date(this.selectedTimeSlot.endTimeInMillis).toISOString()
			if(startTime) {
				this.fromDate = startTime.substr(0,10)
				this.fromTime = startTime.substr(11,5)
			}
			if(endTime) {
				this.endDate = endTime.substr(0,10)
				this.endDate = endTime.substr(11,5)
			}
		}
	},
	updated() {
		this.datePickerWidth = this.$refs.dateTimeSelection.clientWidth - 32
	},
	watch: {
		fromDate(value) {
			this.$emit('updateStartTime', new Date(value + 'T' + this.fromTime).getTime())
		},
		fromTime(value) {
			this.$emit('updateStartTime', new Date(this.fromDate + 'T' + value).getTime())
		},
		toDate(value) {
			this.$emit('updateEndTime', new Date(value + 'T' + this.toTime).getTime())
		},
		toTime(value) {
			this.$emit('updateEndTime', new Date(this.toDate + 'T' + value).getTime())
		}
	}
}
</script>

<style scoped>

</style>