<template>
  <v-navigation-drawer
    ref="drawer"
    :width="width"
    :right="borderPosition === 'left'"
    :color="color"
    permanent
  >
    <slot />
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "ExpandableWidthDrawer",
  props: {
    borderPosition: {
      type: String,
      default: 'right' // also offers left
    },
    borderSize: {
      type: Number,
      default: 3
    },
    defaultWidth: {
      type: Number,
      default: 420
    },
    minWidth: {
      type: Number,
      default: 0
    },
    maxWidth: {
      type: Number,
      default: null
    },
    color: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    width: 420
  }),
  methods: {
    setBorderWidth() {
      let i = this.$refs.drawer.$el.querySelector( ".v-navigation-drawer__border" )
      i.style.width = this.borderSize + "px"
      i.style.cursor = "ew-resize"
    },
    setEvents() {
      const minSize = this.borderSize
      const el = this.$refs.drawer.$el
      const drawerBorder = el.querySelector(".v-navigation-drawer__border")
      const direction = el.classList.contains("v-navigation-drawer--right") ? "left" : "right"

      const _this = this
      let lastF = null
      function resize(e) {
        document.body.style.cursor = "ew-resize"
        const rect = el.getBoundingClientRect()
        let f = direction === "left" ? document.body.scrollWidth - e.clientX : e.clientX - rect.left
        if(lastF !== null && f < lastF && f < _this.minWidth + _this.borderSize + 5) {
          return
        }
        if(lastF !== null && f > lastF && _this.maxWidth !== null && f > _this.maxWidth) {
          return
        }
        lastF = f
        el.style.width = f + "px"
      }

      drawerBorder.addEventListener("mousedown", function(e) {
        if (e.offsetX < minSize) {
          el.style.transition = 'initial'
          document.addEventListener("mousemove", resize, false)
        }
      }, false)

      document.addEventListener("mouseup", function() {
          el.style.transition = ''
          this.width = el.style.width
          document.body.style.cursor = ""
          document.removeEventListener("mousemove", resize, false)
        }, false)
    }
  },
  mounted() {
    this.width = this.defaultWidth
    this.setBorderWidth()
    this.setEvents()
  }
}
</script>

<style scoped>
  .expandable-border-right {
    border-right: 5px solid #000000;
    cursor: ew-resize;
  }
  .expandable-border-left {
    border-left: 5px solid #000000;
    cursor: ew-resize;
  }
</style>