<template>
  <v-dialog
    max-width="800"
    ref="bookingDialog"
    :value="value"
    @input="updateShowDialog"
    @click:outside="abort"
    :persistent="processing"
    scrollable
  >
    <v-card>
      <v-card
        :color="primaryColor"
        :class="textColor(primaryColor)"
        dark
        tile
      >
        <v-card-title>{{ type }} {{ $t('bookingLC') }}</v-card-title>
      </v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-row dense>
              <v-col cols="12">
                <v-card
                  elevation="2"
                  :disabled="bookingIsCancelled"
                >
                  <v-card-title>
                    {{ $t('bookingInformation') }}
                  </v-card-title>
                  <v-card-text>
                    <div
                      class="pb-3"
                      v-if="type.toLowerCase() === 'edit'"
                    >
                      <v-btn
                        v-if="bookingIsCancelled"
                        x-small
                        color="error"
                      >
                        {{ $t('cancelled') }}
                      </v-btn>
                      <v-btn
                        v-else
                        x-small
                        color="success"
                      >
                        {{ $t('active') }}
                      </v-btn>
                    </div>
                    <v-row dense>
                      <v-col
                        cols="12"
                        class="pb-2"
                      >
                        <v-autocomplete
                          v-model="selectedChannel"
                          item-value="uuid"
                          item-text="label"
                          :items="channels"
                          return-object
                          :label="$t('channel')"
                          persistent-hint
                          hint="This is to see availability of time slots"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="selectedLocation"
                          item-value="uuid"
                          item-text="label"
                          :items="locations"
                          return-object
                          :label="$t('location')"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="selectedItem"
                          item-value="uuid"
                          item-text="title"
                          return-object
                          :items="items"
                          :label="$t('item')"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          disabled
                          :value="1"
                          :items="[1,2,3,4,5,6,7,8,9,10]"
                          :label="$t('quantity')"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-checkbox
                          :label="$t('emailTicketToCustomer')"
                          v-model="emailTicketToCustomer"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card
                  elevation="2"
                  :disabled="bookingIsCancelled"
                >
                  <v-card-title>
                    {{ $t('customerInformation') }}
                  </v-card-title>
                  <v-card-text>
                    <v-autocomplete
                      :label="$t('customer')"
                      v-model="customer"
                      :items="customers"
                      item-value="uuid"
                      item-text="address.name_line"
                      return-object
                      @change="selectCustomer"
                      :search-input.sync="customerSearch"
                      :filter="customerFilter"
                    >
                      <template v-slot:no-data>
                        <v-container>
                          <div class="text--secondary text-center">
                            <div class="pb-3">
                              {{ $t('noCustomerWithThatNameFound') }}
                            </div>
                            <div>
                              <v-btn
                                block
                                :color="primaryColor"
                                :class="textColor(primaryColor)"
                                @click="createCustomer"
                              >
                                {{ $t('createCustomer') }}
                              </v-btn>
                            </div>
                          </div>
                        </v-container>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data !== 'object'">
                          <v-list-item-content v-text="data.item" />
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ data.item.address.name_line }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <div>{{ data.item.email }}</div>
                              <div>{{ data.item.phone }}</div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                    <v-form v-model="formFilled">
                      <v-text-field
                        :label="$t('email') + ' *'"
                        v-model="customerEmail"
                        :rules="[rules.required]"
                        :disabled="hasInputData('customerEmail')"
                      />
                      <v-text-field
                        :label="$t('phoneNumber') + ' *'"
                        v-model="customerPhone"
                        :rules="[rules.required]"
                        :disabled="hasInputData('customerPhone')"
                      />
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card
                  elevation="2"
                  disabled
                >
                  <v-card-title>{{ $t('co') }}</v-card-title>
                  <v-card-subtitle class="error--text">
                    {{ $t('workInProgress') }}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-autocomplete
                      :label="$t('customer')"
                      v-model="co"
                      :items="customers"
                      item-value="uuid"
                      item-text="address.name_line"
                      return-object
                    />
                    <v-text-field
                      :label="$t('email')"
                      v-model="coEmail"
                      :rules="[rules.required]"
                      :disabled="!!(coEmail)"
                    />
                    <v-text-field
                      :label="$t('phoneNumber')"
                      v-model="coPhone"
                      :rules="[rules.required]"
                      :disabled="!!(coPhone)"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <DateTimeSelection
                  :is-disabled="false"
                />
              </v-col>
              <v-col cols="12">
                <PriceCurrencySelection
                  :is-disabled="false"
                  :selected-item="selectedItem"
                />
              </v-col>
              <v-col cols="12">
                <CommentCard
                  :is-disabled="false"
                  @updated="commentUpdated"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-row dense>
              <v-col cols="12">
                <v-card elevation="2">
                  <v-card-title>{{ $t('currentAvailability') }}</v-card-title>
                  <v-card
                    tile
                    :disabled="bookingIsCancelled"
                    elevation="0"
                  >
                    <v-date-picker
                      color="primary"
                      v-model="selectedDate"
                      full-width
                      class="elevation-0"
                      no-title
                      style="height: 310px"
                    />
                  </v-card>
                  <v-card
                    elevation="0"
                    tile
                    color="grey lighten-3"
                    :disabled="bookingIsCancelled"
                  >
                    <v-card-title>
                      <div>
                        <div v-if="slotGroups !== null && slotGroups.length > 0">
                          <template v-for="(group, index) in slotGroups">
                            <v-subheader :key="index + '-div'">
                              {{ $t('kl') }} {{ group }}
                            </v-subheader>

                            <template v-for="(slot, index2) in slots[group]">
                              <span :key="index2 + slot.label + '-slot'">
                                <v-btn
                                  :loading="slot.processing"
                                  :disabled="isSlotDisabled(slot)"
                                  v-if="slot.selected"
                                  class="mb-4"
                                  color="green"
                                  :key="index2 + slot.label"
                                  :style="slot.isBlocked ? 'border: 2px red solid' : ''"
                                  @click="deselectSlot(slot)"
                                >
                                  {{ slot.label }}
                                </v-btn>
                                <v-btn
                                  :loading="slot.processing"
                                  :disabled="isSlotDisabled(slot)"
                                  class="mb-4"
                                  :key="index2 + slot.label"
                                  :style="slot.isBlocked ? 'border: 2px red solid' : ''"
                                  v-else
                                  @click="selectSlot(slot)"
                                >
                                  {{ slot.label }}
                                </v-btn>
                                <v-menu>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="mr-4 mb-4"
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      <v-icon>mdi-information</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <v-list-item>
                                      <v-list-item-title>
                                        <span class="font-weight-bold">{{ $t('available:') }}</span> {{ slot.availableCount }}
                                      </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-title>
                                        <span class="font-weight-bold">{{ $t('currentlyReserved:') }}</span> {{ slot.reservationCount }}
                                      </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="slot.isBlocked">
                                      <v-list-item-title class="red--text font-weight-bold">
                                        {{ $t('blockedFromCustomerBooking') }}
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </span>
                            </template>
                          </template>
                        </div>
                        <div
                          class="mt-2 mb-2"
                          v-if="slotGroups !== null && slotGroups.length === 0"
                        >
                          <v-alert
                            icon="mdi-lightbulb"
                            prominent
                            text
                            type="info"
                          >
                            <span class="text-break">
                              {{ $t('everythingIsFullyBookedForSelectedDate') }}
                            </span>
                          </v-alert>
                        </div>
                        <div
                          class="mt-2 mb-2"
                          v-if="errorMessage !== null && errorMessage.length"
                        >
                          <div
                            v-for="(error, index) in errorMessage"
                            :key="'error-'+index"
                          >
                            <v-alert
                              icon="mdi-alert"
                              prominent
                              text
                              type="error"
                            >
                              <span class="text-break">
                                {{ error }}
                              </span>
                            </v-alert>
                          </div>
                        </div>
                        <div
                          class="mt-3 mb-3 text-center"
                          v-if="gettingAvailableTimeSlots"
                        >
                          <v-progress-linear
                            indeterminate
                            color="primary"
                          />
                          <span>{{ $t('gettingAvailableTimeSlots') }}</span>
                        </div>
                        <div
                          class="mt-3 mb-3 text-center"
                          v-if="slotGroups === null && !gettingAvailableTimeSlots && !errorMessage && !errorMessage.length"
                        >
                          <div>{{ $t('pleaseSelect:') }}</div>
                          <div v-if="!selectedLocation">
                            {{ $t('aLocation') }}
                          </div>
                          <div v-if="!selectedChannel">
                            {{ $t('aSalesChannel') }}
                          </div>
                          <div v-if="!selectedItem">
                            {{ $t('anItem') }}
                          </div>
                          <div v-if="!selectedDate">
                            {{ $t('aDate') }}
                          </div>
                        </div>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-text class="title py-3">
        <div class="body-1">
          <span class="font-weight-bold">{{ $t('selectedLocation:') }}</span> {{ selectedLocation && selectedLocation.label }}
        </div>
        <div class="body-1">
          <span class="font-weight-bold">{{ $t('selectedItem:') }}</span> {{ selectedItem && selectedItem.title }}
        </div>
        <div class="body-1">
          <span class="font-weight-bold">{{ $t('selectedDate:') }}</span> {{ dateDisplay }}
        </div>
        <div class="body-1">
          <span class="font-weight-bold">{{ $t('selectedTime:') }}</span> {{ timeSlotsDisplay }}
        </div>
        <div v-if="bookingIsCancelled">
          {{ $t('bookingHasBeenCancelled') }}
        </div>
        <div v-else-if="price()">
          {{ $t('priceTotal:') }} {{ price() }} {{ selectedItem && selectedItem.price && selectedItem.price.currency_code }}
        </div>
        <div v-else>
          {{ $t('pleaseSelectItemDateAndTimeToSeePrice') }}
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end">
        <v-container class="py-0">
          <v-row
            dense
            justify="end"
          >
            <v-col
              cols="12"
              sm="auto"
            >
              <v-btn
                color="error"
                large
                @click="abort"
                block
                :disabled="processing"
              >
                {{ $t('close') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="auto"
              v-if="type === 'Edit'"
            >
              <v-btn
                color="error"
                large
                block
                @click="cancelBookingAction = !cancelBookingAction"
                :disabled="processing || bookingIsCancelled"
              >
                {{ $t('cancelBooking') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="auto"
            >
              <v-btn
                color="primary"
                large
                block
                :disabled="processing || bookingIsCancelled"
                :loading="processing"
                @click="processBooking"
              >
                {{ type.toLowerCase() === 'edit' ? 'Update' : type }} {{ $t('bookingLC') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="auto"
              v-if="type.toLowerCase() === 'create'"
            >
              <v-btn
                color="primary"
                large
                block
                :disabled="processing || wip || bookingIsCancelled"
                :loading="processing"
                @click="processBookingWithPayment"
              >
                {{ type }} {{ $t('bookingAndPay') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-if="cancelBookingAction"
      v-model="cancelBookingAction"
      max-width="800"
    >
      <v-card>
        <v-card-title>
          {{ $t('cancelBooking?') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('areYouSureCancelBooking?') }}
        </v-card-subtitle>
        <v-card-subtitle v-if="cancelError">
          {{ cancelError }}
        </v-card-subtitle>
        <v-card-actions>
          <v-row
            dense
            justify="end"
          >
            <v-col
              cols="12"
              sm="auto"
            >
              <v-btn
                color="error"
                block
                large
                @click="clickCancel"
                :disabled="processing"
              >
                {{ $t('goBack') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="auto"
            >
              <v-btn
                color="primary"
                block
                large
                @click="cancelBookingMethod"
                :loading="processing"
                :disabled="processing"
              >
                {{ $t('confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CustomerDialog
      :key="showCreateCustomer"
      v-model="customer"
      :dialog="showCreateCustomer"
      @updated="customerUpdated"
      @created="customerCreated"
      @dialog="customerDialogChanged"
      @closed="showCreateCustomer = false"
    />
  </v-dialog>
</template>

<script>
import CustomerDialog from "./CustomerDialog"
import DateTimeSelection from "./booking/DateTimeSelection"
import PriceCurrencySelection from "./booking/PriceCurrencySelection"
import CommentCard from "./booking/CommentCard"

/**
 * @property {Object} bookingDialog
 */

export default {
	name: "BookingDialog",
	components: {
		CommentCard,
		PriceCurrencySelection,
		DateTimeSelection,
		CustomerDialog,
	},
	props: {
		value: {
			type: Boolean
		},
		type: {
			type: String,
			default: ''
		},
		booking: {
			type: Object,
			default: () => {}
		},
		date: {
			type: Date,
			default: new Date()
		}
	},
	data() {
		return {
			processing: false,
			cancelBookingAction: false,
			formFilled: false,
			showCreateCustomer: false,
			customer: null,
			customerSearch: null,
			customerUUID: null,
			customerName: null,
			customerEmail: null,
			customerPhone: null,
			co: null,
			coUUID: null,
			coName: null,
			coEmail: null,
			coPhone: null,
			selectedLocation: null,
			selectedChannel: null,
			availableTimeSlots: null,
			selectedItem: null,
			selectedTimeSlots: [],
			selectItemInList: null,
			selectLocationInList: null,
			selectedDate2: new Date(),
			selectedDate: new Date().toISOString().substr(0, 10),
			errorMessage: [],
			cancelError: null,
			customPrice: null,
			selectedCurrency: 'ISK',
			customTime: [],
			wip: true,
			emailTicketToCustomer: true,
			orderComment: null,
		}
	},
	beforeMount() {
		this.$store.dispatch('getCurrentSystemTime')
	},
	mounted() {
		if(this.type.toLowerCase() === 'edit') {
			this.emailTicketToCustomer = false
		}
		if(this.booking) {
			if(this.booking.customer !== null && this.booking.customer !== undefined) {
				this.customer = this.booking.customer
				this.customerUUID = this.booking.customer.uuid
				if(this.booking.customer.address) {
					this.customerName = this.booking.customer.address.name_line
				}
				this.customerPhone = this.booking.customer.phone
				this.customerEmail = this.booking.customer.email
			}
			if(this.booking.co !== null && this.booking.co !== undefined) {
				this.co = this.booking.co
				this.coUUID = this.booking.co.uuid
				if(this.booking.co.address) {
					this.coName = this.booking.co.address.name_line
				}
				this.coPhone = this.booking.co.phone
				this.coEmail = this.booking.co.email
			}
		}
		if(this.booking && this.booking.startsAtTime) {
			const d = new Date((this.booking.startsAtTime*1000))
			this.selectedDate = d.toISOString().substr(0, 10)
			const t = d.toLocaleTimeString().substr(0,5)
			this.selectedTimeSlots.push({
				startTimeInMillis: this.booking.startsAtTime*1000,
				endTimeInMillis: this.booking.endsAtTime*1000,
				text: t
			})
		} else {
			if(this.date) {
				this.selectedDate = this.date.toISOString().substr(0, 10)
			}
		}

		if(!this.selectedLocation && this.locations.length) {
			if(this.booking && this.booking.location) {
				const found = this.locations.find(location => location.uuid === this.booking.location.uuid)
				if(found) {
					this.selectedLocation = found
				} else {
					this.locations.push(this.booking.location)
					this.selectedLocation = this.booking.location
				}
			} else {
				this.selectedLocation = this.locations[0]
			}
		}

		if(!this.selectedChannel && this.channels.length) {
			this.selectedChannel = this.channels[0]
		}

		if(!this.selectedItem && this.items.length) {
			if(this.booking && this.booking.item) {
				const found = this.items.find(item => item.uuid === this.booking.item.uuid)
				if(found) {
					this.selectedItem = found
				} else {
					this.items.push(this.booking.item)
					this.selectedItem = this.booking.item
				}
				// for(let i in this.items) {
				// 	if(this.booking.item.uuid === this.items[i].uuid) {
				// 		this.selectedItem = this.items[i]
				// 	}
				// }
			} else {
				this.selectedItem = this.items[0]
			}
		}
	},
	watch: {
		date(newDate) {
			if(newDate && this.selectedDate === null && (!this.booking)) {
				this.selectedDate = this.date.toISOString().substr(0, 10)
			}
		},
		selectedItem(newItem) {
			if(newItem === null && this.items.length) {
				if(this.booking && this.booking.item) {
					const found = this.items.find(item => item.uuid === this.booking.item.uuid)
					if(found) {
						this.selectedItem = found
					} else {
						this.items.push(this.booking.item)
						this.selectedItem = this.booking.item
					}
				} else {
					this.selectedItem = this.items[0]
				}

			} else {
				if(this.value && this.selectedDate && this.selectedLocation) {
					this.getAvailableTimeSlots(this.selectedDateAsObject, newItem, this.selectedLocation, this.selectedChannel)
				}
			}
		},
		selectedLocation(newLocation) {
			if(newLocation === null && this.locations.length) {
				if(this.booking && this.booking.location) {
					const found = this.locations.find(location => location.uuid === this.booking.location.uuid)
					if(found) {
						this.selectedLocation = found
					} else {
						this.locations.push(this.booking.location)
						this.selectedLocation = this.booking.location
					}
				} else {
					this.selectedLocation = this.locations[0]
				}
			} else {
				if(this.value && this.selectedDate && this.selectedItem) {
					this.getAvailableTimeSlots(this.selectedDateAsObject, this.selectedItem, newLocation, this.selectedChannel)
				}
			}
		},
		selectedDate(newDate) {
			if(newDate && this.value && this.selectedItem && this.selectedLocation) {
				this.getAvailableTimeSlots(this.selectedDateAsObject, this.selectedItem, this.selectedLocation, this.selectedChannel)
			}
		},
		selectedChannel(newChannel) {
			if(newChannel && this.selectedDateAsObject && this.value && this.selectedItem && this.selectedLocation) {
				this.getAvailableTimeSlots(this.selectedDateAsObject, this.selectedItem, this.selectedLocation, newChannel)
			}
		}
	},
	methods: {
		isSlotDisabled(slot) {

			if(slot.processing || this.processing) {
				return true
			}

			if(this.$store.state.organization.uuid === '32036a02-fd37-4044-bbb1-e55970e4531f' && slot.bookingCount > 0) {
				return true
			}

			return false
		},
		commentUpdated(comment) {
			this.orderComment = comment
		},
		customerFilter(item, queryText, itemText) {
			if(itemText.toLowerCase().includes(queryText.toLowerCase())) {
				return itemText
			}
			if(item.phone && item.phone.toString().toLowerCase().includes(queryText.toString().toLowerCase())) {
				return itemText
			}
			if(item.email && item.email.toLowerCase().includes(queryText.toLowerCase())) {
				return itemText
			}
		},
		hasInputData(input) {
			let customer = this.customer
			switch(input) {
				case 'customerName':
					return !!(customer && customer.address && customer.address.name_line)
				case 'customerPhone':
					return !!(customer && customer.phone)
				case 'customerEmail':
					return !!(customer && customer.email)
				case 'coName':
					return null
				case 'coPhone':
					return null
				case 'coEmail':
					return null
				default:
					return false
			}
		},
		selectCustomer(customer) {
			this.customer = customer
			if(customer) {
				this.customerPhone = customer.phone
				this.customerEmail = customer.email
			} else {
				this.customerPhone = null
				this.customerEmail = null
			}
		},
		customerCreated(customer) {
			this.showCreateCustomer = false
			this.customer = this.$store.getters.getCustomerByUUID(customer.uuid)
		},
		customerUpdated(customer) {
			this.showCreateCustomer = false
			this.customer = this.$store.getters.getCustomerByUUID(customer.uuid)
		},
		customerDialogChanged(dialog) {
			this.showCreateCustomer = dialog
		},
		createCustomer() {
			this.showCreateCustomer = !this.showCreateCustomer
		},
		textColor(color) {
			return this.$store.getters.textButtonColor(color)
		},
		clickCancel() {
			this.cancelBookingAction = !this.cancelBookingAction
			this.cancelError = null
		},
		processBookingWithPayment() {
			if(!this.type) {
				return
			}

			let order = {

			}

			let newBooking = {
				item: this.selectedItem.uuid,
				customer: this.customer.uuid,
				namespace: this.selectedItem.namespace
			}

			if(this.co) {
				newBooking.co = this.co.uuid
			}

			this.$emit('pay', order)
		},
		processBooking() {
			if(!this.type) {
				return
			}

			if(this.type.toLowerCase() === 'create') {
				this.createBookingMethod()
			} else if(this.type.toLowerCase() === 'edit') {
				this.updateBookingMethod()
			} else {
				return null
			}
		},
		createBookingMethod() {
			this.processing = true

			this.errorMessage = []

			if(!this.selectedItem || !this.selectedItem.uuid || !this.customer || !this.customer.uuid) {
				this.errorMessage.push('Unable to create booking due to missing identifiers.')
				this.processing = false
				return
			}

			if(!this.formFilled) {
				this.errorMessage.push('Missing required fields')
				this.processing = false
				return
			}

			let newBooking = {
				item: this.selectedItem.uuid,
				customer: this.customer.uuid,
				namespace: this.selectedItem.type,
				sendTicket: this.emailTicketToCustomer
			}

			if(this.co) {
				newBooking.co = this.co.uuid
			}

			let bookings = []

			if(this.selectedTimeSlots && this.selectedTimeSlots.length) {
				// newBooking.startsAtTime = Math.round(this.selectedTimeSlots[0].startTimeInMillis/1000)
				// newBooking.endsAtTime = Math.round(this.selectedTimeSlots[0].endTimeInMillis/1000)
				for(let i in this.selectedTimeSlots) {
					let booking = JSON.parse(JSON.stringify(newBooking))
					booking.startsAtTime = Math.round(this.selectedTimeSlots[i].startTimeInMillis/1000)
					booking.endsAtTime = Math.round(this.selectedTimeSlots[i].endTimeInMillis/1000)
					bookings.push(booking)
				}
			} else {
				this.errorMessage.push('You need to select time for the booking.')
				this.processing = false
				return
			}

			if(bookings && bookings.length) {
				if((!this.hasInputData('customerPhone') && this.customerPhone) || (!this.hasInputData('customerEmail') && this.customerEmail)) {
					let c = {
						uuid: this.customer.uuid
					}
					if(!this.hasInputData('customerPhone') && this.customerPhone) {
						c.phone = this.customerPhone
					}
					if(!this.hasInputData('customerEmail') && this.customerEmail) {
						c.email = this.customerEmail
					}

					this.$store.dispatch('updateCustomer', c).then(result => {
						if(result !== null && result !== undefined) {
							const promiseBookings = bookings.map(booking => this.$store.dispatch('createBooking', booking))
							Promise.all(promiseBookings).then((r) => {
								if(r && r.length && !r.some(i => i === undefined)) {
									let message = 'You have successfully created the booking(s).'
									this.$store.commit('updateActionSuccess', {
										message: 'Success',
										subMessage: message
									})
									this.abort()
								}
							})
						} else {
							this.errorMessage.push('Unable to update customer')
						}
					}).catch(() => {
						this.errorMessage.push('Unable to update customer')
					}).finally(() => {
						this.processing = false
					})
				} else {
					const promiseBookings = bookings.map(booking => this.$store.dispatch('createBooking', booking))
					Promise.all(promiseBookings).then((result) => {
						if(result && result.length && !result.some(i => i === undefined)) {
							let message = 'You have successfully created the booking(s).'
							this.$store.commit('updateActionSuccess', {
								message: 'Success',
								subMessage: message
							})
							this.abort()
						}
					}).finally(() => {
						this.processing = false
						this.abort()
					})
				}
			}
		},
		updateBookingMethod() {
			this.processing = true

			if(!this.booking.item || !this.booking.item.uuid || !this.booking.customer || !this.booking.customer.uuid) {
				this.errorMessage.push('Unable to cancel booking due to missing identifiers.')
				return
			}

			let b = {
				uuid: this.booking.uuid,
				item: this.selectedItem.uuid,
				customer: this.customer.uuid,
				namespace: this.booking.namespace,
			}

			if(this.co && this.co !== this.booking.co) {
				b.co = this.co.uuid
			}

			let bookings = []

			if(this.selectedTimeSlots && this.selectedTimeSlots.length) {
				if(this.selectedTimeSlots.length === 1) {
					if(this.selectedTimeSlots[0].startTimeInMillis !== this.booking.startsAtTime && this.selectedTimeSlots[0].endTimeInMillis !== this.booking.endsAtTime) {
						b.startsAtTime = Math.round(this.selectedTimeSlots[0].startTimeInMillis/1000)
						b.endsAtTime = Math.round(this.selectedTimeSlots[0].endTimeInMillis/1000)
						bookings.push(b)
					} else if(this.selectedTimeSlots[0].startTimeInMillis !== this.booking.startsAtTime) {
						b.startsAtTime = Math.round(this.selectedTimeSlots[0].startTimeInMillis/1000)
						bookings.push(b)
					} else if(this.selectedTimeSlots[0].endTimeInMillis !== this.booking.endsAtTime) {
						b.endsAtTime = Math.round(this.selectedTimeSlots[0].endTimeInMillis/1000)
						bookings.push(b)
					}
				} else {
					for(let i in this.selectedTimeSlots) {
						let booking = b
						booking.startsAtTime = Math.round(this.selectedTimeSlots[i].startTimeInMillis/1000)
						booking.endsAtTime = Math.round(this.selectedTimeSlots[i].endTimeInMillis/1000)
						bookings.push(booking)
					}
				}
			} else {
				this.errorMessage.push('You need to select time for the booking.')
				return
			}

			if(bookings && bookings.length) {
				let promiseBookings = []
				for(let k in bookings) {
					promiseBookings[k] = this.$store.dispatch('updateBooking', bookings[k])
				}
				Promise.all(promiseBookings).then((result) => {
					if(result) {
						let message = 'You have successfully updated the booking.'
            this.$store.commit('updateActionSuccess', {
              message: 'Success',
              subMessage: message
            })
					}
				}).finally(() => {
					this.processing = false
					this.abort()
				})
			}
		},
		cancelBookingMethod() {
			this.processing = true

			this.cancelError = null

			if(!this.booking.item || !this.booking.item.uuid || !this.booking.customer || !this.booking.customer.uuid) {
				this.cancelError = 'Unable to cancel booking due to missing identifiers.'
				return
			}

			const b = {
				uuid: this.booking.uuid,
				item: this.booking.item.uuid,
				customer: this.booking.customer.uuid,
				namespace: this.booking.namespace,
				status: false
			}

			this.$store.dispatch('updateBooking', b)
					.then(result => {
						if(result) {
							this.cancelBookingAction = false
							let message = 'You have successfully cancelled the booking.'
              this.$store.commit('updateActionSuccess', {
                message: 'Success',
                subMessage: message
              })
							this.abort()
						}
					})
					.finally(() => {
						this.processing = false
					})
		},
		deselectSlot(slot) {

			this.availableTimeSlots.find(timeSlot => {
				if(timeSlot.timestampInMillis === slot.startTimeInMillis) {
					timeSlot.processing = true
				}
			})

			const index = this.selectedTimeSlots.findIndex(s => s.key === slot.key)
			this.selectedTimeSlots.splice(index, 1)
			this.selectedTimeSlots = this.selectedTimeSlots.sort((a,b) => a.key - b.key)

			this.availableTimeSlots.find(timeSlot => {
				if(timeSlot.timestampInMillis === slot.startTimeInMillis) {
					timeSlot.processing = false
					timeSlot.selected = false
				}
			})

			// this.$store.dispatch('releaseAvailableTimeSlot', {
			// 	itemUUID: this.selectedItem.uuid,
			// 	startTimeInMillis: slot.startTimeInMillis,
			// 	endTimeInMillis: slot.endTimeInMillis
			// }).then(result => {
			// 	if(result.data.data.releaseAvailableTimeSlot) {
			//
			// 		this.availableTimeSlots.find(timeSlot => {
			// 			if(timeSlot.timestampInMillis == slot.startTimeInMillis) {
			// 				timeSlot.reservedForCurrentSession = false
			// 				timeSlot.processing = false
			// 			}
			// 		})
			//
			// 	} else {
			// 		this.errorMessage[slot.text.slice(0,2)] = 'Could not deselect time'
			// 	}
			// }).finally(() => {
			// })
		},
		selectSlot(slot) {
			this.availableTimeSlots.find(timeSlot => {
				if(timeSlot.timestampInMillis === slot.startTimeInMillis) {
					timeSlot.processing = true
				}
			})

			this.selectedTimeSlots.push(slot)
			this.selectedTimeSlots = this.selectedTimeSlots.sort((a,b) => a.key - b.key)

			this.availableTimeSlots.find(timeSlot => {
				if(timeSlot.timestampInMillis === slot.startTimeInMillis) {
					timeSlot.processing = false
					timeSlot.selected = true
				}
			})

			// this.$store.dispatch('reserveAvailableTimeSlot', {
			// 	itemUUID: this.selectedItem.uuid,
			// 	startTimeInMillis: slot.startTimeInMillis,
			// 	endTimeInMillis: slot.endTimeInMillis,
			// 	quantity: 1
			// }).then(result => {
			// 	if(result.data.data.reserveAvailableTimeSlot) {
			// 		delete this.errorMessage[slot.text.slice(0,2)]
			// 		slot.reservedForCurrentSession = true
			// 		this.availableTimeSlots.find(timeSlot => {
			// 			if(timeSlot.timestampInMillis == slot.startTimeInMillis) {
			// 				timeSlot.reservedForCurrentSession = true
			// 				timeSlot.processing = false
			// 				this.selectedTimeSlots.push(slot)
			// 				this.selectedTimeSlots = this.selectedTimeSlots.sort((a,b) => a.key - b.key)
			// 			}
			// 		})
			// 	} else {
			// 		alert('Því miður er þessi tími ekki lengur í boði')
			// 	}
			// }).finally(() => {
			// })

		},
		getAvailableTimeSlots(date, item, location, channel) {
			this.availableTimeSlots = null

			const start = new Date(date.getTime())
			start.setHours(0,0,0,0)

			const end = new Date(date.getTime())
			end.setHours(23,59,59,999)

			this.$store.dispatch('getAvailableTimeSlots', {
				itemUUID: item.uuid,
				locationUUID: location.uuid,
				channelUUID: channel.uuid,
				startTimeInMillis: start.getTime(),
				endTimeInMillis: end.getTime()
			}).then(response => {

				let availableTimeSlots = response

				for(let i in availableTimeSlots) {
					availableTimeSlots[i].processing = false
				}

				this.availableTimeSlots = availableTimeSlots
			}).catch(() => {
				this.errorMessage.push('Error. Could not get available times.')
			})
		},
		selectDate(e) {
			this.selectedDate = e
		},
		selectItem(item) {
			this.selectedItem = item
		},
		updateShowDialog() {
			this.$emit('input', this.$refs.bookingDialog.value)
		},
		abort() {
			this.customerUUID = null
			this.customerName = null
			this.customerPhone = null
			this.customerEmail = null
			this.coUUID = null
			this.coName = null
			this.coPhone = null
			this.coEmail = null
			this.selectedItem = null
			this.selectItemInList = null
			this.selectLocationInList = null
			this.selectedTimeSlots = []
			this.selectedDate = null
			this.selectedLocation = null
			this.errorMessage = []
			this.$emit('input', false)
		},
		price() {
			if(this.selectedItem && (this.selectedItem.uuid === 'cb8755d2-e3b6-463e-9798-79883c7af94a' || this.selectedItem.uuid === '2f0a3564-4620-45b6-a811-9fbe05fefa35')) {
				let currentDate = null

				if(this.selectedDate) {
					currentDate = new Date(this.selectedDate).getDay()
				} else {
					currentDate = new Date().getDay()
				}

				if(currentDate === 0 || currentDate === 5 || currentDate === 6) {
					this.selectedItem.price.amount = 5990
				} else {
					this.selectedItem.price.amount = 4990
				}
			}
			if(this.selectedItem && this.selectedItem.price && this.selectedItem.price.amount) {
				return this.selectedItem.price.amount * this.selectedTimeSlots.length
			}
		}
	},
	computed: {
		channels() {
			return this.$store.state.channels
		},
		bookingIsCancelled() {
			if(this.type.toLowerCase() === 'create') {
				return
			}
			return !(this.booking && this.booking.status && this.booking.status === '1')
		},
		selectedItemCurrencies() {
			if(this.selectedItem && this.selectedItem.price) {
				let currencies = []

				for(let i in this.selectedItem.price) {
					if(i === 'currency_code') {
						currencies.push(this.selectedItem.price[i])
					}
				}
				return currencies
			}
			return ['ISK']
		},
		customers() {
			return this.$store.state.customers
		},
		selectedDateAsObject() {
			return new Date(this.selectedDate)
		},
		reservedSlots() {
			return this.availableTimeSlots.filter(timeSlot => {
				return timeSlot.reservedForCurrentSession
			})
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		gettingAvailableTimeSlots() {
			return this.$store.state.gettingAvailableTimeSlots
		},
		slotGroups() {

			if(this.slots === null) {
				return null
			}

			return Object.keys(this.slots)
		},
		slots() {

			if(this.availableTimeSlots === null) {
				return null
			}

			const slots = {}
			const step = 60 * 5 * 1000
			let timestamp = 1591963200000 + (60 * 30 * 1000)

			for(let i in this.availableTimeSlots) {

				const timeSlot = this.availableTimeSlots[i]

				const date = new Date(timeSlot.timestampInMillis)

				if(slots[date.getUTCHours()] === undefined) {
					slots[date.getUTCHours()] = []
				}

				slots[date.getUTCHours()].push({
					...timeSlot,
					key: date.getTime(),
					startTimeInMillis: timeSlot.timestampInMillis,
					endTimeInMillis: timeSlot.timestampInMillis + timeSlot.paddingInMillis,
					selected: this.booking.startsAtTime === Math.round(timeSlot.timestampInMillis/1000) || this.selectedTimeSlots.some(slot => slot.timestampInMillis === timeSlot.timestampInMillis)
				})

				timestamp = timestamp + step
			}

			return slots
		},
		dateDisplay() {
			if(this.selectedDate) {
				return this.selectedDateAsObject.toLocaleDateString()
			} else {
				return null
			}
		},
		timeSlotsDisplay() {
			if(this.selectedTimeSlots && this.selectedTimeSlots.length) {
				let sl = this.selectedTimeSlots.map(slot => slot.text)
				if(sl.length < 2) {
					return sl[0]
				} else {
					return sl.slice(0, -1).join(', ') + ' and ' + sl.slice(-1)
				}
			} else {
				return 'None'
			}
		},
		rules() {
			return this.$store.getters.rules
		},
		items: function() {
			return this.$store.state.items.filter(item => item.type === 'event')
		},
		locations: function() {
			return this.$store.state.locations.filter(location => location.label)
		}
	},
	updated() {
		if(this.slotGroups && this.slotGroups.length) {
			this.slotGroups.forEach(value => {
				if(this.errorMessage[value] === undefined) {
					delete this.errorMessage[value]
				}
			})
		}
		if(this.customer) {
			this.customerPhone = this.customer.phone
			this.customerEmail = this.customer.email
		}
	}
}
</script>

<style scoped>
</style>